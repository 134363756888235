import "../css/Diaporama.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import io from "socket.io-client";
import { Polaroid } from "../components/Polaroid";
import { QrCode } from "../components/QrCode";
import SwitchMode from "../components/SwitchMode";

const MAX_PHOTOS = 50;
const HARD_RELOAD_TIMEOUT = 1000 * 3600 * 12; // 12 hours

const modes = ["single", "multi", "both"];
const MULTI_MODE_INTERVAL = 30 * 1000; // 30 seconds

let interval;

export const PhotoWall = () => {
  let params = useParams();
  const socket = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [pinCode, setPinCode] = useState(null);
  const [isStacked, setIsStacked] = useState(mode === "single");

  let [searchParams, setSearchParams] = useSearchParams();

  const bgImageParam = searchParams.get("background");
  const mode = searchParams.get("mode") || "single";

  React.useEffect(() => {
    if (mode === "single" || mode === "multi") {
      clearInterval(interval);
      setIsStacked(mode === "single");
    }

    if (mode === "both") {
      interval = setInterval(() => {
        setIsStacked(!isStacked);
      }, MULTI_MODE_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [mode, isStacked, pinCode]);

  React.useEffect(() => {
    if (mode === "single" || mode === "multi") {
      setIsStacked(mode === "single");
      clearInterval(interval);
    }
  }, [mode, isStacked]);

  // hard reload every 12 hours, for permanent displays
  React.useEffect(() => {
    setTimeout(() => window.location.reload(), HARD_RELOAD_TIMEOUT);
  });

  const backgroundImage = bgImageParam
    ? `url(${bgImageParam})`
    : "url(/photowall-background.jpeg)";

  const onStart = (allPhotos) => {
    const photos = JSON.parse(allPhotos);
    console.log("onStart", photos);
    setPhotos(photos);
  };

  const onEventInfo = (eventStr) => {
    const event = JSON.parse(eventStr);
    setPinCode(event?.shortCode);
  };

  const onNewImage = (photoStr) => {
    const photo = JSON.parse(photoStr);
    socket.current?.emit("fb-screen", {
      event: "PRE-LOAD-OK",
      id: photo.id,
    });
    setPhotos((prevPhotos) => {
      if (
        prevPhotos.some((item) => item.src === photo.url) ||
        prevPhotos.length === MAX_PHOTOS
      ) {
        setTimeout(() => setPhotos([]), 3000);
        return prevPhotos.map((p) => ({ ...p, hide: true }));
      } else {
        return [
          ...prevPhotos,
          {
            src: photo.url,
            description: photo.text,
          },
        ];
      }
    });
  };
  useEffect(() => {
    const host =
      location.hostname === "localhost"
        ? "https://slideshow.clickty.synovia.fr"
        : window.location.host;
    socket.current = io(`${host}/events?code=${params.code}`, {
      transports: ["websocket"],
      reconnection: true,
    });
    socket.current.on("all_photo", onStart);
    socket.current.on("next_photo", onNewImage);
    socket.current.on("event_info", onEventInfo);
    socket.current.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.current.connect();
      }
      // else the socket will automatically try to reconnect
    });

    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, [params.code]);

  const divStyle = {
    width: "100vw",
    height: "100vh",
    position: "relative",
    backgroundImage,
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const imgFillAbsoluteDiv = {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const bottomSpace = "2vw";

  const logoStyle = {
    width: window.innerWidth > window.innerHeight ? "30vh" : "30vw",
    position: "absolute",
    bottom: bottomSpace,
    right: bottomSpace,
    opacity: "0.7",
  };

  const unit = window.innerWidth > window.innerHeight ? "vh" : "vw";

  return (
    <div style={divStyle}>
      <div
        style={{
          position: "absolute",
          width: `10${unit}`,
          height: `10${unit}`,
          top: `3${unit}`,
          right: `5${unit}`,
          opacity: "0.7",
          cursor: "pointer",
        }}
      >
        <SwitchMode
          code={params.code}
          mode="diaporama"
          imgStyle={imgFillAbsoluteDiv}
        />
      </div>
      <div
        onClick={() => {
          const modeIndex = modes.findIndex((m) => m === mode);

          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            mode: modes[modeIndex + 1] ? modes[modeIndex + 1] : modes[0],
          });
        }}
        style={{
          position: "absolute",
          width: `10${unit}`,
          height: `10${unit}`,
          top: `15${unit}`,
          right: `5${unit}`,
          opacity: "0.7",
          cursor: "pointer",
        }}
      >
        <img src="/stack-icon-2.png" style={imgFillAbsoluteDiv} />
      </div>
      {photos.map((item, i) => {
        return (
          <Polaroid
            key={i}
            isStacked={isStacked}
            src={item.src}
            description={item.description}
            hide={item.hide}
          />
        );
      })}

      <QrCode
        eventCode={pinCode}
        width={
          window.innerWidth > window.innerHeight
            ? window.innerHeight / 5
            : window.innerWidth / 5
        }
        style={{
          position: "absolute",
          bottom: bottomSpace,
          left: bottomSpace,
          opacity: 0.4,
        }}
      />
      <img style={logoStyle} src="/clickty2.png" />
    </div>
  );
};
