import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import io from "socket.io-client";
import { useParams } from "react-router-dom";

const MAX_PHOTOS = 50;

const animations = [
  {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1.2 },
  },
  {
    initial: { opacity: 0, x: "-100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
  },
  {
    initial: { opacity: 0, y: "-100%" },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: "100%" },
  },
  {
    initial: { opacity: 0, rotate: -180 },
    animate: { opacity: 1, rotate: 0 },
    exit: { opacity: 0, rotate: 180 },
  },
];

const Motion = () => {
  let params = useParams();
  const [imageUrls, setImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAnimation, setCurrentAnimation] = useState(animations[0]);
  const [isReady, setIsReady] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    const host =
      location.hostname === "localhost"
        ? "https://slideshow.clickty.synovia.fr"
        : window.location.host;
    socket.current = io(`${host}/events?code=${params.code}`, {
      transports: ["websocket"],
      reconnection: true,
    });
    //   socket.current.on("all_photo", onStart);
    socket.current.on("next_photo", onNewImage);
    //   socket.current.on("event_info", onEventInfo);
    socket.current.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.current.connect();
      }
      // else the socket will automatically try to reconnect
    });

    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, [params.code]);

  const onNewImage = (photoStr) => {
    const photo = JSON.parse(photoStr);
    socket.current?.emit("fb-screen", {
      event: "PRE-LOAD-OK",
      id: photo.id,
    });
    setImageUrls((prevPhotos) => {
      if (imageUrls.length >= 3 && !isReady) {
        setIsReady(true);
      }

      if (
        prevPhotos.some((item) => item.src === photo.url) ||
        prevPhotos.length === MAX_PHOTOS
      ) {
        setTimeout(() => setImageUrls([]), 3000);
        return prevPhotos.map((p) => ({ ...p, hide: true }));
      } else {
        return [
          ...prevPhotos,
          {
            src: photo.url,
            description: photo.text,
          },
        ];
      }
    });
  };

  useEffect(() => {
    if (isReady) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        setCurrentAnimation(
          animations[Math.floor(Math.random() * animations.length)]
        );
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [isReady, imageUrls.length]);

  if (!isReady) {
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-black text-white text-2xl">
        Chargement des images...
      </div>
    );
  }

  return (
    <div className="relative w-screen h-screen overflow-hidden bg-black">
      <AnimatePresence initial={false}>
        <motion.img
          key={currentIndex}
          src={imageUrls[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="absolute inset-0 w-full h-full object-contain"
          initial={currentAnimation.initial}
          animate={currentAnimation.animate}
          exit={currentAnimation.exit}
          transition={{ duration: 0.8 }}
        />
      </AnimatePresence>
    </div>
  );
};

export default Motion;
